import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DetailsService } from 'src/app/services/details.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-producer',
  templateUrl: './producer.html',
  styleUrls: ['./producer.less'],
})
export class Producer {
  private httpService: HttpService;
  private detailsService: DetailsService;

  public constructor(
    httpService: HttpService,
    detailsService: DetailsService,
    public translate: TranslateService
  ) {
    this.setHttpService(httpService);
    this.setDetailsService(detailsService);
  }

  public getBurnAmount(): string {
    return this.getDetailsService().getDetails().logistics.roastedBeans.quantity;
  }

  public getBurnFee(): string {
    return this.getDetailsService().getDetails().logistics.roastedBeans.amountInEuro.toFixed(2);
  }

  public getWashed(): string {
    return this.getDetailsService().getDetails().logistics.roastedBeans.logistics.gBeansList[0].lProcessedBeans.quantity;
  }

  public getWetmills(): any {
    return this.getDetailsService().getProducer().getTransactions().filter((e: any) => { return e.sender.includes('Wetmill') });
  }

  public getRoasters(): any {
    return this.getDetailsService().getProducer().getTransactions().filter((e: any) => { return e.sender.includes('Roaster') });
  }

  /**
   * Getters & Setters
   */

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(http: HttpService): void {
    this.httpService = http;
  }

  public getDetailsService(): DetailsService {
    return this.detailsService;
  }

  public setDetailsService(detailsService: DetailsService): void {
    this.detailsService = detailsService;
  }
}
