import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Brand } from './components/brand/brand';
import { Farmer } from './components/farmer/farmer';
import { Footer } from './components/footer/footer';
import { LandingPage } from './components/landing-page/landing-page';
import { Producer } from './components/producer/producer';
import { Transactions } from './components/transactions/transactions';
import { You } from './components/you/you';

const routes: Routes = [
  {
    path: '',
    component: LandingPage,
  },
  {
    path: 'farmer',
    component: Farmer,
  },
  {
    path: 'producer',
    component: Producer,
  },
  {
    path: 'brand',
    component: Brand,
  },
  {
    path: 'you',
    component: You,
  },
  {
    path: 'footer',
    component: Footer,
  },
  {
    path: 'transactions',
    component: Transactions,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
