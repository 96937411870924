import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../environments/environment";

@Injectable()
export class HttpService {
  public static readonly URI_API: string = `https://us-central1-provenance-310914.cloudfunctions.net/api/provenance/v1`;
  //public static readonly URI_API: string = `http://localhost:5001/provenance-310914/us-central1/api/provenance/v1`;


  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.setHttpClient(httpClient);
  }

  public get(key: string, data: Object = {}, customHeaders = {}): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders(
      Object.assign({
          Authorization: environment.auth,
          pbat: environment.pbatID,
        }, customHeaders)
    );

    console.log(headers);

    /* Moet beter kunnen. (Object > queryString ) */
    let params: string[] = [];

    for (let key in data)
      params.push(new HttpParams().set(key, data[key]).toString());
    /**/

    return this.getHttpClient().request('GET', `${key}?${params.join('&')}`, {
      headers: headers,
    });
  }

  public post(url: string, data: Object, options: Object = {}): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: environment.auth,
      PBATID: environment.pbatID,
    });

    let http: Observable<Object>;

    /* Moet beter kunnen. (Object > queryString ) */
    let params: string[] = [];

    for (let key in data)
      params.push(new HttpParams().set(key, data[key]).toString());
    /**/

    const mergedOptions: Object = Object.assign(
      {
        withCredentials: true,
        headers: headers,
      },
      options
    );

    new Promise((resolve, reject) => {
      http = this.getHttpClient().post(url, params.join('&'), mergedOptions).pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
      http.toPromise().then((res) => {
        //
      });
    });

    return http;
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error.message);
  }

  /*
   * Getters
   */

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  /*
   * Setters
   */

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }
}
