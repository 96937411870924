<nav class="navbar navbar-expand-lg navbar-light pink-background">
  <div class="navbar-nav mr-auto">
    <a class="navbar-brand" href="https://www.moyeecoffee.com/" target="_blank">
      <img
        src="/assets/global/images/moyee_m_logo3.png"
        height="70px"
        class="d-inline-block align-top"
        alt="logo"
      />
    </a>
  </div>

  <span class="navbar-text">
    <div class="dropdown">
      <span>
        <a>
          <img
            class="language-switcher"
            src="{{'header-language-switcher-flag-active' | translate}}"
            alt=""
          />
        </a>

        <i class="dropdown-icon fas fa-angle-down"></i>
      </span>

      <div class="dropdown-content">
        <a class="dropdown-item" (click)="switchLanguage(countryCode1.value)">
          <img
            src="{{'header-language-switcher-flag-not-active' | translate}}"
            alt="Language selector"
          />
          <input
            type="hidden"
            value="{{'header-language-switcher-flag-not-active-code' | translate}}"
            #countryCode1
          />
        </a>

        <a class="dropdown-item" (click)="switchLanguage(countryCode2.value)">
          <img
            src="{{'header-language-switcher-flag-not-active2' | translate}}"
            alt="Language selector"
          />
          <input
            type="hidden"
            value="{{'header-language-switcher-flag-not-active-code2' | translate}}"
            #countryCode2
          />
        </a>
      </div>
    </div>

    <a
      class="btn btn-pink"
      href="{{'header-link-shop' | translate}}"
      type="button"
      target="_blank"
    >
      {{ 'header-store-text' | translate }}
    </a>
  </span>
</nav>

<div class="container-fluid pink-background main-text text-center" id="header">
  <div class="lp-title-1">
    <span class="d-lg-block"> {{ 'lp-title-1' | translate }} </span>

    <span class="d-lg-block text-dark">
      <span class="text-white">‘</span>
      {{ 'lp-title-2' | translate }}
      <span class="text-white">’</span>
    </span>

    <span class="d-lg-block"> {{ 'lp-title-3' | translate }} </span>
  </div>

  <div class="lp-paragraph-1">
    <span class="text-white"> {{ 'lp-paragraph-1' | translate }} </span>
  </div>
</div>

<div class="container-fluid text-center">
  <div class="content-header">
    <span class="text-dark d-lg-block lp-title-4">
      {{ 'lp-title-4' | translate }}
    </span>

    <span class="text-pink d-lg-block lp-title-5">
      {{ 'lp-title-5' | translate }}
      <span class="text-dark remove-spacing-left">.</span>
    </span>
  </div>

  <div
    id="farmers"
    class="carousel slide"
    data-ride="carousel"
    data-touch="true"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <a routerLink="/farmer">
          <img
            class="lp-farmer-1"
            src="/assets/global/images/landingpage/lp-farmer-1.PNG"
            alt="lp-farmer"
          />
        </a>

        <p class="text-pink lp-carousel-title">
          {{ 'lp-subtitle-1' | translate }}
        </p>

        <p class="text-black">{{ 'lp-paragraph-2' | translate }}</p>
      </div>

      <div class="carousel-item">
        <a routerLink="/producer">
          <img
            src="/assets/global/images/landingpage/lp-farmer-2.PNG"
            alt="farmer"
          />
        </a>

        <p class="text-pink lp-carousel-title">
          {{ 'lp-subtitle-2' | translate }}
        </p>

        <p class="text-black">{{ 'lp-paragraph-3' | translate }}</p>
      </div>

      <div class="carousel-item">
        <a routerLink="/brand">
          <img
            src="/assets/global/images/landingpage/lp-farmer-3.PNG"
            alt="farmer"
          />
        </a>

        <p class="text-pink lp-carousel-title">
          {{ 'lp-subtitle-3' | translate }}
        </p>

        <p class="text-black">{{ 'lp-paragraph-4' | translate }}</p>
      </div>

      <div class="carousel-item">
        <a routerLink="/you">
          <img
            class="lp-farmer-4"
            src="/assets/global/images/landingpage/lp-farmer-4.PNG"
            alt="farmer"
          />
        </a>

        <p class="text-pink lp-carousel-title">
          {{ 'lp-subtitle-4' | translate }}
        </p>

        <p class="text-black">{{ 'lp-paragraph-5' | translate }}</p>
      </div>
    </div>

    <ul class="carousel-indicators">
      <li
        class="indicator active"
        data-target="#farmers"
        data-slide-to="0"
      ></li>
      <li class="indicator" data-target="#farmers" data-slide-to="1"></li>
      <li class="indicator" data-target="#farmers" data-slide-to="2"></li>
      <li class="indicator" data-target="#farmers" data-slide-to="3"></li>
    </ul>

    <button routerLink="/farmer" class="btn lp-button-1">
      {{ 'lp-button-1' | translate }}
    </button>
  </div>

  <img
    src="/assets/global/images/awards.png"
    class="img-fluid awardsImage"
    alt="awards"
  />
</div>

<div class="container-fluid text-center black-background text-white">
  <div class="row justify-content-md-center">
    <div class="col col-lg-2">
      <p class="lp-footertitle-1">{{ 'lp-footertitle-1' | translate }}</p>

      <p class="lp-footertitle-2">{{ 'lp-footertitle-2' | translate }}</p>
    </div>

    <div class="col-md-auto">
      <span class="d-block font-weight-bold">
        {{ 'lp-footerinfo-1' | translate }}
        <span class="font-weight-normal">
          {{ 'lp-footerinfo-2' | translate }}
        </span>
      </span>

      <span class="d-block font-weight-bold">
        {{ 'lp-footerinfo-3' | translate }}
        <span class="font-weight-normal">
          {{ 'lp-footerinfo-4' | translate }}
        </span>
      </span>

      <span class="d-block font-weight-bold">
        {{ 'lp-footerinfo-5' | translate }}
        <span class="font-weight-normal">
          {{ 'lp-footerinfo-6' | translate }}
        </span>
      </span>

      <span class="d-block font-weight-bold">
        {{ 'lp-footerinfo-7' | translate }}
        <span class="font-weight-normal">
          {{ 'lp-footerinfo-8' | translate }}
        </span>
      </span>
    </div>

    <div class="col col-lg-2">
      <img
        src="/assets/global/images/landingpage/lp-footerlogo.png"
        class="img-fluid"
        alt="footerlogo"
      />
    </div>
  </div>
</div>
