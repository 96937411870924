import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';

import { AppRoutingModule } from './app-routing.module';
import { Farmer } from './components/farmer/farmer';
import { Brand } from './components/brand/brand';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import { LandingPage } from './components/landing-page/landing-page';
import { Producer } from './components/producer/producer';
import { Transactions } from './components/transactions/transactions';
import { You } from './components/you/you';
import { Shell } from './components/shell/shell';

import { HttpService } from './services/http.service';
import { DetailsService } from './services/details.service';

@NgModule({
  declarations: [
    Shell,
    Header,
    LandingPage,
    Farmer,
    Producer,
    Brand,
    You,
    Footer,
    Transactions,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyATkijZXZhnHllZaS8hZkJFcUk6QwgxDuk',
    }),
  ],
  providers: [HttpService, DetailsService],
  bootstrap: [Shell],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
