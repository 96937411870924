import {
  Component,
  OnInit,
  ɵCompiler_compileModuleSync__POST_R3__,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.less'],
})
export class Footer implements OnInit {
  public href: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.href = this.router.url;
    // console.log(this.router.url);
    const id = this.href.split('/')[1];
    document.getElementById(id).classList.add('active');
    // console.log(id);
  }
}
