import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/app/environments/environment';
import { DetailsService } from 'src/app/services/details.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './shell.html',
  styleUrls: ['./shell.less'],
})
export class Shell {
  private details: DetailsService;

  private title: string;
  private userLanguage: string;
  private languages: Array<string>;

  public constructor(
    public translate: TranslateService,
    details: DetailsService,
    public httpService: HttpService,
    private titleService: Title
  ) {
    const storedLanguage = localStorage.getItem('lang');

    this.setDetails(details);
    this.setTitle(environment.title);
    this.titleService.setTitle(this.getTitle());
    this.setLanguages(navigator.language.split('-'));

    this.translate.addLangs(['en', 'nl', 'de']);
    this.translate.setDefaultLang(this.getUserLanguage() || 'nl');

    if (storedLanguage) {
      this.setUserLanguage(storedLanguage);
    } else {
      const newLanguage = this.getLanguages()[0];

      this.setUserLanguage(newLanguage);
      localStorage.setItem('lang', newLanguage);
    }
  }

  public switchLang(lang: string): void {
    this.translate.use(lang);
  }

  /**
   * Getters & setters
   */

  public getDetails(): DetailsService {
    return this.details;
  }

  public setDetails(details: DetailsService): void {
    this.details = details;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public getUserLanguage(): string {
    return this.userLanguage;
  }

  public setUserLanguage(lang: string): void {
    this.userLanguage = lang;
  }

  public getLanguages(): Array<string> {
    return this.languages;
  }

  public setLanguages(languages: Array<string>): void {
    this.languages = languages;
  }
}
