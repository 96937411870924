import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';

import FarmerClass from '../../classes/Farmer';
import { TranslateService } from '@ngx-translate/core';
import { DetailsService } from 'src/app/services/details.service';

@Component({
  selector: 'app-farmer',
  templateUrl: './farmer.html',
  styleUrls: ['./farmer.less'],
})
export class Farmer {
  private httpService: HttpService;
  private detailsService: DetailsService;

  public constructor(
    httpService: HttpService,
    detailsService: DetailsService,
    public translate: TranslateService
  ) {
    this.setHttpService(httpService);
    this.setDetailsService(detailsService);
  }

  log(s) {
    console.log('log', s);
  }

  /**
   * Getters & Setters
   */

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(http: HttpService): void {
    this.httpService = http;
  }

  public getDetailsService(): DetailsService {
    return this.detailsService;
  }

  public setDetailsService(detailsService: DetailsService): void {
    this.detailsService = detailsService;
  }
}
