import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.html',
  styleUrls: ['./landing-page.less'],
})
export class LandingPage {
  private httpService: HttpService;
  private translateService: TranslateService;

  public constructor(
    httpService: HttpService,
    translateService: TranslateService
  ) {
    this.setHttpService(httpService);
    this.setTranslateService(translateService);
  }

  public switchLanguage(countryCode: string): void {
    this.getTranslateService().use(countryCode);
    localStorage.setItem('lang', countryCode);
  }

  /**
   * Getters & setters
   */

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(httpService: HttpService): void {
    this.httpService = httpService;
  }

  public getTranslateService(): TranslateService {
    return this.translateService;
  }

  public setTranslateService(translateService: TranslateService): void {
    this.translateService = translateService;
  }
}
