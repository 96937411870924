import { Component, OnInit } from '@angular/core';
import Transaction from 'src/app/classes/Transaction';
import { DetailsService } from 'src/app/services/details.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.html',
  styleUrls: ['./transactions.less'],
})
export class Transactions {
  private detailsService: DetailsService;
  private httpService: HttpService;

  private transactions: Array<Transaction>;

  public constructor(detailsService: DetailsService, httpService: HttpService) {
    this.setDetailsService(detailsService);
    this.setHttpService(httpService);

    this.getHttpService().get(`${HttpService.URI_API}/transaction/all/`).subscribe((i: any) => {
      this.getHttpService().get(`${HttpService.URI_API}/valuedistribution/redcherries/`).subscribe((k: any) => {
        this.setTransactions(i.data.map((j: any) => {
          const correct: any = k.data.find((l: any) => {
            return l.txnId == j.txid;
          }) || {};

          console.log({
            j: j,
            correct: correct
          })
          return new Transaction(j.txid, j.sender, j.receiver, correct.quantity || 0, correct.price || 0, j.blockHash, j.blockheight, j.previousHash, j.txnDate, j.uuid, j.document);
        }));
      });
    });
  }

  public getDate(timestamp: number): string {
    return new Date(timestamp).toLocaleDateString("nl");
  }

  public getPaymentSlip(tnxid: string): string {
    return this.transactions.find((l: any) => {
      return l.txid == tnxid;
    }).getDocument() || this.getDetailsService().getTransactionByTxnId(tnxid).paymentSlip;
  }

  log(s) {
    console.log("xxxx", s);
  }

  /**
   * Getters & Setters
   */

  public getDetailsService(): DetailsService {
    return this.detailsService;
  }

  public setDetailsService(detailsService: DetailsService): void {
      this.detailsService = detailsService;
  }

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(httpService: HttpService): void {
      this.httpService = httpService;
  }

  public getTransactions(): Array<Transaction> {
    return this.transactions;
  }

  public setTransactions(transactions: Array<Transaction>): void {
      this.transactions = transactions;
  }
}
