<div class="container-fluid" *ngIf="getDetailsService().getFarmer()">
  <div class="row">
    <div class="col-lg-12">
      <h1>Transaction history</h1>
    </div>
  </div>

  <div class="row">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Date</th>
          <th>ID</th>
          <th>Document</th>
          <th>Kg</th>
          <th>Price</th>
          <th>Price/kg</th>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of getTransactions(); let index = index;">
          <td>
            {{getDate(i.getTimestamp())}}
          </td>

          <td>
            <a href="#" data-toggle="modal" [attr.data-target]="'#pbat-' + index">
              {{i.getTxid()}}
              </a>
          </td>

          <td>
            <a href="#" data-toggle="modal" [attr.data-target]="'#download-' + index">
              {{getPaymentSlip(i.getTxid()) ? 'View' : ''}}
            </a>
          </td>

          <td>
            {{i.getQuantity() || '-'}}
          </td>

          <td>
            {{i.getPriceInEur() || '-'}}
          </td>

          <td>
            {{i.getPricePerKg() || '-'}}
          </td>

          <td>
            {{i.getSender()}}
          </td>

          <td>
            {{i.getReceiver()}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngFor="let i of getTransactions(); let index = index;">
    <div class="modal fade" [id]="'pbat-' + index" tabindex="-1" role="dialog" [attr.aria-labelledby]="'pbat-' + index" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ID information</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row modalRow">
              <div class="col-md-4">
                <span class="font-weight-bold">ID</span>
              </div>
              <div class="col-md-8">
                <span>{{'id'}}</span>
              </div>
            </div>

            <div class="row modalRow">
              <div class="col-md-4">
                <span class="font-weight-bold">Block Hash</span>
              </div>
              <div class="col-md-8">
                <span>{{i.getBlockHash()}}</span>
              </div>
            </div>

            <div class="row modalRow">
              <div class="col-md-4">
                <span class="font-weight-bold">Time Stamp</span>
              </div>
              <div class="col-md-8">
                <span>{{i.getTimestamp()}}</span>
              </div>
            </div>

            <div class="row modalRow">
              <div class="col-md-4">
                <span class="font-weight-bold">Previous Hash</span>
              </div>
              <div class="col-md-8">
                <span>{{i.getPreviousHash()}}</span>
              </div>
            </div>

            <div class="row modalRow">
              <div class="col-md-4">
                <span class="font-weight-bold">Block Height</span>
              </div>
              <div class="col-md-8">
                <span>{{i.getBlockHeight()}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" [id]="'download-' + index" tabindex="-1" role="dialog" [attr.aria-labelledby]="'#download-' + index" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              DOWNLOAD information
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
  
          <div class="modal-body">
            <div class="row modalrow">
              <img style="width: 100%; height: auto;" class="img-fluid" [src]="getPaymentSlip(i.getTxid())"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
